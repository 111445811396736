// eslint-disable-next-line import/no-extraneous-dependencies
import { LDFlagSet } from 'launchdarkly-js-sdk-common'
import { useContext } from 'react'

import { isDevelopment } from '../config'
import { OrganizationContext } from '../contexts/OrganizationContext'

declare module 'launchdarkly-js-sdk-common' {
  /**
   * Feature Toggleを定義します
   * Launch Darklyで定義されているキー名と一致させる必要があります
   * @see: https://app.launchdarkly.com/default/production/features
   */
  interface LDFlagSet {
    // チャネルトーク
    channelTalkChat: boolean
    // マネーフォワードにのみ提供する機能
    moneyForward: boolean
    // 1 on 1
    oneOnOne: boolean
    // 一括更新を促すslackDM機能
    bulkUpdateSlackDm: boolean
    // SmartHRの連携を表示できるようにする
    smarthr: boolean
  }
}

/**
 * valueはdefault値を指しており、Launch Darklyとの通信が失敗した場合に参照されます
 */
export const DEFAULT_FEATURE_TOGGLE_FLAGS: LDFlagSet = {
  channelTalkChat: false,
  moneyForward: false,
  oneOnOne: false,
  bulkUpdateSlackDm: false,
  smarthr: false,
}

/**
 * Feature toggleのflag
 * @desc localでflagを変更して確認したい場合.env.localや.env.development.localを
 *              frontendディレクトリに作成すればにそれぞれのfeature toggleのflagを変更できる
 * @see {@link https://create-react-app.dev/docs/adding-custom-environment-variables/#what-other-env-files-can-be-used}
 * */
export const useFlags = (): LDFlagSet => {
  // 2024/09/12現在 launchdarklyの利用を中止したため、固定値を返す
  const flags = {
    channelTalkChat: false,
    moneyForward: false,
    bulkUpdateSlackDm: false,
    smarthr: false,
  }
  // 特定の顧客に1on1機能を出さないようにするための処理 #12112
  const organization = useContext(OrganizationContext)
  const oneOnOneEnabled = organization?.oneOnOneEnabled ?? false
  if (isDevelopment()) {
    const localToggle: LDFlagSet = {
      channelTalkChat: process.env.REACT_APP_FLAG_CHANNEL_TALK_CHAT
        ? process.env.REACT_APP_FLAG_CHANNEL_TALK_CHAT === 'true'
        : flags.channelTalkChat,
      moneyForward: process.env.REACT_APP_FLAG_MONEY_FORWARD
        ? process.env.REACT_APP_FLAG_MONEY_FORWARD === 'true'
        : flags.moneyForward,
      oneOnOne: oneOnOneEnabled,
      bulkUpdateSlackDm: process.env.REACT_APP_FLAG_BULKUPDATE_SLACK_DM
        ? process.env.REACT_APP_FLAG_BULKUPDATE_SLACK_DM === 'true'
        : flags.bulkUpdateSlackDm,
      smarthr: process.env.REACT_APP_FLAG_SMART_HR
        ? process.env.REACT_APP_FLAG_SMART_HR === 'true'
        : flags.bulkUpdateSlackDm,
    }
    return localToggle
  }
  return { ...flags, oneOnOne: oneOnOneEnabled }
}
